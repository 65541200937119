html {
    font-family: sans-serif;
}

body {
    margin: 0px;
    background:#363640;
    /* background-image: linear-gradient(#e1f9ff,#fff); */
}

html, body {
    margin: 0px;
    min-height: 100%;
    height: 100%;
}

* {
box-sizing: border-box;
}

.dx-popup-title .logo-small {
    flex: 1;
}

.dx-popup-title h3 {
    flex: 4;
    margin: 3px 6px;
    text-transform: uppercase;
}

::-webkit-scrollbar {
    width: 12px !important;
    height: 12px !important;
}

/* Track */
::-webkit-scrollbar-track {
    background:transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(179, 179, 179); 
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}


.dx-overlay-wrapper.dx-popup-wrapper.dx-overlay-modal.dx-overlay-shader{
    z-index: 1502 !important;
  }
  

#menu {
    margin-top: 5px;
}

.addBorder{
    border: 2px slid rgb(62, 122, 99);
}
.gridContainer {
    margin: 10px 10px;
    min-height: 200px;
    /* border: 1px solid #e7e7e7; */
}




.globalContainer {
    margin: 10px 10px 0;
    background-color: white;
}
.box-shadow {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background-color: #fff;
    margin: 10px;
}

.dx-item.dx-toolbar-item.dx-toolbar-label{
    max-width: initial !important;
}

.loginContainer{
    min-height: calc(100vh - 50px);
}

.registerContainer {
    min-height: calc(100vh - 200px);
}
.inline{
    display: inline-block;
    vertical-align: top;
}

.nav-right {
    float: right;
    display: inline-block;
    width: auto;
    margin-right: 1px;
}

.tabview {
    height: 50px;
    background-color: #525252;
    color: white;
}

.tabview:hover {
    height: 50px;
    background-color: #525252;
    /* color: magenta; */
}

#finishTransForm .dx-form-group-caption {
    font-size: 17px;
    font-weight: 500;
}

#finishTransForm .dx-form-group-with-caption .dx-form-group-content {
    padding: 0;
}

.TabPanelGrids {
    margin: 10px 10px;
}


.header-box {   
    padding: 8px 10px;
}

.header-item {
    margin: 0px 10px;
    display: inline-block;
}

.header-item:last-of-type {
    height: 29px;
}

.label-item {
    display: inline-block;
    vertical-align: top;
    margin: 10px 5px 0 0;
    font-size: 13px;
}

.item-box{
    display: inline-block;
}

.wizard-button {
    margin: 0 0 25px 0;
}


.num-box{
    width: 150px;
    padding: 5px;
    margin: 5px 5px 5px 10px;
    text-align: center;
    color: white;
    display: inline-block;
    height: 150px;
    vertical-align: top;
    -webkit-box-shadow: 0 7px 11px -2px rgba(0, 0, 0, 0.2); 
    box-shadow: 0 7px 11px -2px rgba(0, 0, 0, 0.2);
}

.analizeContainer{
    display: flex;
    height: calc(100vh - 158px);
}

.squares {
   display: inline-block;
    width: 100%;
}

.filter-form {
    padding: 8px 25px;
}

.charts {
    width: calc(100% - 5px);
    display: flex;
    padding-bottom: 10px;
}

.generareChart, .transportChart, .elimChart, .valorifChart {
    padding: 10px;
    margin: 50px 10px 0px;
    width: calc(48% - 5px);
    border: 1px solid gray;
}

.analyzesChart {
    padding: 10px;
    margin: 0px 10px;
    width: calc(99% - 7px);
    border: 1px solid gray;
}

.analyzesGrid {
    margin: 10px 10px 0;
    width: calc(99% - 7px);
    border: 1px solid gray;
    height: calc(32vh - 28px);
}

#chart {
    width: calc(100% - 25px);
    margin: 20px 10px 0px;
}

.flex-row {
    /* height: 88%; */
}

.dashboardList {
    margin: 10px 0;
    border: 1px solid #3333332b;
    width: 290px;
    box-sizing: border-box;
    height: calc( 100% - 20px);
}

.left-box {
    width: calc(100% - 300px);
}

.blue{
    background: rgb(107, 149, 211);
}
.green{
    background-color: #a9d971;
}
.red{
    background-color: #f26f5a;
}
.purple{
    background-color: #a886e0;
}

.left-part{
    width: 30%;
    display: inline-flex;
}


#footer {
    position: fixed;
    bottom: 0px;
    height: 30px;
    width: 100%;
    /* border-top: 2px solid #339933; */

}

#footer .dx-toolbar {
    background-color: #b1e3f1;
    color: #212529;
}

#footer .dx-toolbar-before {
    margin-left: 5px;
}

#footer .dx-toolbar-after {
    margin-right: 5px;
}

 #header .container-custom .left {
    display: none;
}
#header .container-custom .left #logo-img {
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    display: none;
}

 .col {
    margin-top: 70px;
    box-sizing: border-box;
    float: left;
    padding: 0 15px;
    width: 46%;
 }

.dx-datagrid-header-panel .dx-toolbar-before {
    margin-left: 6px;
    font-weight: bold;
    font-size: 14px;
    color: rgb(149, 149, 149);
}

.options {
    position: relative;
    font-weight: bold;
    z-index: 1;
    top:10px;
    line-height: 38px;
    width: 350px;
    color: black;
}

h3 {
    font-weight: 500;
}

.current-company-box{
    margin: 5px 0;
}
.current-company-box h3{
    margin-left: 10px;
}

#actionsColumn div{
    margin: 2px;
}

.custom {
    text-decoration: none !important;
}

/* .dx-success {
    color: #339933 !important;
    font-size: 16px;
}

.dx-danger {
    color: red !important;
    font-size: 16px;
} */
.item a, .sub-item a{
    cursor: pointer;
}

.toolbarImage {
    width: 17px;
    display: inline-block !important;
    height: 17px;
    vertical-align: middle;
    margin-right: 6px;
    margin-top: -5px;
}

.fieldTextbox {
    width: 100px;
}

#mainNavbar .dx-tabs-wrapper {
    display: flex;
    width: 100%;
    min-width: 1110px;
    text-align: left;
    background-color: #b1e3f1;
    height: 100%;
    box-shadow: 2px 2px 2px #afced7;
}

#mainNavbar .dx-tab.dx-tab-selected:last-child:before{
    height: 0px;
}

#mainNavbar .dx-tab:nth-last-child(1), #mainNavbar .dx-tab:nth-last-child(2),
#mainNavbar .dx-tab:nth-last-child(3), #mainNavbar .dx-tab:nth-last-child(4){
    min-width: 36px;
    padding: 9px 0px;
    /* float:right; */
    text-align: right;
}

#mainNavbar .dx-tab:nth-last-child(4) {
    margin-left: auto;
}

#header #mainNavbar .dx-tab.dx-tab-selected:after ,#header .dx-tab.dx-state-focused ,#subheader .dx-tab.dx-tab-selected:after ,#subheader .dx-tab.dx-state-focused{
    border: none;
}

#subheader .dx-tabs-wrapper {
    float: right;
    width: 100%;
    text-align: right;
    height: 35px;
}

#mainNavbar .dx-tabs-wrapper > .dx-tab.dx-item:first-child {
    /* padding: 0;
    margin-left: 15px; */
    font-weight: bold;
}
#mainNavbar .dx-scrollable-horizontal .dx-tab.dx-item:last-child{
    float:none;
}

#header .dx-tabs .dx-tab {
    font-size: 18px;
    /* color: #212529; */
    text-decoration: none;
    vertical-align: middle;
    /* -webkit-transition: all .3s ease-in-out; */
    display: inline-block;
    border: none;
    background-color: transparent;

}

.closeButton {
    right: 10px;
    top: 3px;
    cursor: pointer;
    position: absolute;
}

.closeButton dx-button {
    border:none;
}
.popupHeader{
    height: 30px;
}

.popupImage {
    float:left; 
    margin: 3px 0px;
    display:block;
}

.popupImage img{
    height: 25px;
}
.wizardImage img{
    height: 35px;
}

#loginModal{
    position: absolute;
    top: 0;
    background: white;
    width: 100%;
    height: 100%;
    z-index: 9000;
}

.popupText {
    margin: 3px 0 0 10px;
    height: 20px;
    font-size: 19px;
    font-weight: bold;
}

.dx-row.dx-column-lines.dx-virtual-row {
    display: none;
}

.dx-row.bold{
    font-weight: bold;
}

.dx-row.stockAlert{
    color: red;
    font-weight: bold;
}

/* .dx-field-item-label-location-top{ 
    padding: 5px 0 3px;
} */
.dx-layout-manager .dx-field-item:not(.dx-first-row){
    padding-top: 5px;
}

/* .dx-popup-title.dx-toolbar .dx-toolbar-items-container {
    height: 56px;
}

.dx-popup-bottom.dx-toolbar .dx-toolbar-items-container {
    height: 42px;
} */

/* logo */
#mainNavbar .dx-tabs-wrapper > .dx-tab.dx-item:first-child img {
    height: 50px; 
    margin-top: -8px;
}

.bread-item::before .path-name{
    fill: tomato;
    fill-rule: evenodd;
}

.dx-datagrid-rowsview {
    border-bottom: 1px solid #ddd;
}

.row{
    display: flex;
    flex-wrap: wrap;
    flex: 1;
}

.dx-red{
    color: red;
    font-weight: bold;
}

.dx-green{
    color: green;
    font-weight: bold;
}

.dx-yellow{
    color: #ffca21;
    font-weight: bold;
}

.dx-bold {
    font-weight: bold;
}

.remove-expand{
    cursor: default;
}

.wizardFooter {
    border-top:  1px solid gray;
    width: 97%;
    padding-top: 10px;
    position: absolute;
    bottom: 10px; 
    margin: 5px 0 10px 0;
}

.wizardFooter dx-button[icon=chevronright] {
    float: right;
}

ul.wizard, ul.wizard li {
    margin: 2px 0 0;
    padding: 0;
  }
  ul.wizard {
    counter-reset: num;
  }
  ul.wizard li {
    list-style-type: none;
    float: left;
    position: relative;
    text-align: center;
  }
  ul.wizard li::before {
    display: block;
    counter-increment: num;
    content: counter(num);
    width: 1.5em;
    height: 1.5em;
    text-align: center;
    line-height: 1.5em;
    border-radius: 50%;
    background: #9fcf68;
    margin: 0 auto;
  }
  ul.wizard li ~ li::after {
    content: '';
    position: absolute;
    width: 100%;
    right: 50%;
    height: 4px;
    background-color: #9fcf68;
    top: calc(.75em - 2px);
    z-index: -1;
  }
  ul.wizard li.active::before {
    background: #317928;
    color: white;
  }
  ul.wizard .active ~ li::before,
  ul.wizard .active ~ li::after {
    background: #b7b7b7;
  }
  ul.wizard span {
    display: inline-block;
    color: #7d7d7d;
    font-size: 12px;
    font-family: Arial;
  }

  .wizardHeader {
    height: 45px !important;
  }

  @media (max-width: 880px) {
    #mainNavbar .dx-tabs-wrapper > .dx-tab.dx-item:first-child img {
        height: 50px;
        margin-top: -18px;
    }
}

@media (max-width: 945px) {

    .generareChart, .transportChart, .elimChart, .valorifChart {
        padding: 10px;
        margin: 50px 10px 0px;
        width: calc(45% - 5px);
        border: 1px solid gray;
    }

    .num-box{
        width: 125px;
        padding: 5px;
        margin: 5px 5px 5px 10px;
        text-align: center;
        color: white;
        display: inline-block;
        height: 150px;
        vertical-align: top;
        -webkit-box-shadow: 0 7px 11px -2px rgba(0, 0, 0, 0.2); 
        box-shadow: 0 7px 11px -2px rgba(0, 0, 0, 0.2);
    }

    .header-item {
        width: 190px;
        height: 50px;
    }

    div.header-item:last-child {
        margin-top: 5px;
    }

}

@media (max-width: 750px) {

    .num-box{
        width: 105px;
        padding: 5px;
        margin: 5px 5px 5px 10px;
        text-align: center;
        color: white;
        display: inline-block;
        height: 150px;
        vertical-align: top;
        -webkit-box-shadow: 0 7px 11px -2px rgba(0, 0, 0, 0.2); 
        box-shadow: 0 7px 11px -2px rgba(0, 0, 0, 0.2);
    }

    .charts {
        display: block;
        margin-bottom: 50px;
    }
    .generareChart, .transportChart, .elimChart, .valorifChart {
        padding: 10px;
        margin: 50px 10px 0px;
        width: calc(95% - 8px);
        border: 1px solid gray;
    }

    #chart {
        width: calc(100% - 25px);
        margin: 20px 10px 0px;
    }

}


.company-symbol{
    background-color:#e5634e;
    color:white;
    font-weight: 700;
    padding:10px;
    display: inline-block;
}
.company-name{
    display: inline-block;
}
.company-symbol-list{
    background-color:#b1e3f1;
    padding:5px;
    font-weight: 600;
}
.submenu-list{
    font-size: 16px;
}
.submenu-list-company{
    font-size: 14px;
}
.submenu-list .dx-list-item, .submenu-list-company .dx-list-item{
    border-top:none;
}
.submenu-popover .dx-popover-arrow{
    display: none;
}
.submenu-popover .dx-popup-content{
    padding: 10px;
}
a.info-tab{
    position: static;
    display: inline;
    opacity: 1;
    background-color: inherit;
}
.first-st{
    margin-block-end: 0;
    margin-block-start: 0;
    margin: 8px 9px;
}
.second-st{
    margin-block-end: 0;
    margin-block-start: 0;
}

.center-div{
    width:300px;
    margin: 0 auto;
}

.form-header{
    background-color: #b1e3f1;
}
.form-header h1{
    text-align: center;
    margin:0;
    padding: 0.67em 0 0;
}

.filter-box {
    padding: 10px;
}

.content-block {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
}

.dx-field-item-help-text {
    font-size: 10px;
    font-style: italic;
}

.dx-datagrid-export-button .dx-icon-export-excel-button:before {
    content: url(assets/img/export.svg);
  }

  .dx-datagrid-export-button {
      border: none;
  }

  .report-form {
        width: 600px;
        margin: 0 auto;
        padding: 10px;
        height: calc(100vh - 158px);
  }

  .report-footer {
      margin-top: 10px;
      text-align: center;
  }

  .recheck {
      vertical-align: middle;
      color: red;
  }

  .theme-switcher {
      margin-left: 10px;
  }

  
   .header-toolbar.dx-toolbar .dx-toolbar-items-container {
    height: calc( 40px * var(--isCompact) + 55px * (1 - var(--isCompact)));
  }

  .iconUrl-support {
    height: calc( 14px * var(--isCompact) + 24px * (1 - var(--isCompact)));
    vertical-align: middle;
    filter: invert(calc(.54 * var(--isCompact) + .45 * (1 - var(--isCompact))));
  }

  .gridContainer .dx-icon.dx-icon-column-chooser {
    color: #333;
  }

  .account-body .dx-switch-wrapper {
      top: 0px;
  }


  .generateForm .dx-item-content.dx-box-item-content .dx-first-row.dx-field-item.dx-col-7 {
      padding: 0;
      margin-top: 20px;
  }

  .custom-text-box{
      width: 100%;
      /* width: calc(100% - 40px); */
      display:inline-block;
  }

  .custom-add{
      position: absolute;
      border-style: none;
      right: 48px;
      vertical-align: initial;
  }

  .TabPanelGrids td.dx-command-edit.dx-command-edit-with-icons { 
    display: flex;
  }

/*************************** MOBILE RESPONSIVE **************************************/
@media only screen and (max-width: 768px) {


.dx-datagrid-header-panel .dx-toolbar-menu-container .dx-button{
    margin-left: 0px;
}

.dx-datagrid-search-panel.dx-textbox.dx-texteditor.dx-editor-underlined.dx-searchbox.dx-show-clear-button.dx-texteditor-empty.dx-widget{
    width: 90px!important;
    overflow: hidden;
}

.dx-datagrid-search-panel.dx-textbox.dx-texteditor.dx-editor-underlined.dx-searchbox.dx-show-clear-button.dx-widget{
    width: 90px!important;
}

.dx-datagrid, .dx-gridbase-container, .generareChart{
    zoom: 0.8;
}

.dx-overlay-content.dx-popup-normal.dx-resizable{
    min-width: 90vw!important;
    transform: translate(10px, 20px)!important;
}

.dx-popup-content, .dx-toolbar.dx-widget.dx-visibility-change-handler.dx-collection.dx-popup-title{
    zoom: 0.9;
    max-height: calc(80vh - 201px)!important;
}

.dx-popup-bottom.dx-toolbar .dx-toolbar-items-container{
    height: 76px;
}

.dx-popup-bottom.dx-toolbar .dx-toolbar-item{
    display: table-row;
}

.dx-popup-title.dx-toolbar .dx-toolbar-items-container {
    height: 50px;
}

.dx-datagrid-header-panel{
    padding-left: 5px;
    padding-right: 5px;
}

.dx-datagrid-group-panel .dx-group-panel-message {
    white-space: normal;
    width: 70px;
    height: 60px;
    font-size: 8px;
}

.report-form {
    width: 90vw;
}

.globalContainer{
    overflow: scroll;
}

#company-options{
    height: 12px!important;
    width: 12px!important;
    padding: 12px!important;
    border-radius: 10px!important;
    background: url(/assets/img/company.png);
    background-size: contain;
}

#company-options p{
    display: none;
}

#company-options p:after{
    content: "Co";
}

.report-footer .dx-button{
    margin-bottom: 10px;
}

.accountContainer{
    height: fit-content;
}

.account-body{
    width: 90%!important;
    padding-top: 10px!important;
}

.user-symbol{
    height: 80px!important;
    width: 80px!important;
}

.symbol-text{
    line-height: 0.8;
}
}

.fatExpired{
    font-weight: bold;
    color: red;
}
/****************************** END MOBILE ******************************************/
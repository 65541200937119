.content {
  line-height: 1.5;
}
.content h2 {
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.content-block {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
}
.screen-x-small .content-block {
  margin-left: 20px;
  margin-right: 20px;
}

.responsive-paddings {
  padding: 20px;
}
.screen-large .responsive-paddings {
  padding: 40px;
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.full-height-scrollable > div > div > .dx-scrollable-content {
  height: 100%;
}
.full-height-scrollable > div > div > .dx-scrollable-content > .dx-scrollview-content {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}